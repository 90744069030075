@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  font-size: 40px;
  line-height: 52px;
  font-weight: bold;
}

h2 {
  font-size: 34px;
  line-height: 46px;
  font-weight: medium;
}

h3 {
  font-size: 30px;
  line-height: 42px;
  font-weight: medium;
}

h4 {
  font-size: 28px;
  line-height: 40px;
  font-weight: medium;
  @apply text-coal-250;
}

h5 {
  font-size: 24px;
  line-height: 36px;
  font-weight: medium;
}

h6 {
  font-size: 22px;
  line-height: 34px;
  font-weight: regular;
}

p {
  font-size: 16px;
  line-height: 32px;
  font-weight: 600;
  @apply text-coal-250;
}

.p-large {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
}

span {
  font-size: 12px;
  font-weight: 400;
  @apply text-coal-250;
}

.p-normal {
  font-size: 18px;
  line-height: 27px;
}

.p-small {
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.active-top-sales {
  background-color: #1160FF;
  color: white;
}

button,
a {
  transition: all 0.5s ease;
}

.detailSwiper {
  position: static !important;
}

.detailSwiper .swiper-pagination {
  text-align: right;
  bottom: 33px !important;
  left: 12px !important;
  z-index: 5 !important;
}

.news_pagination {
  gap: 14px;
  overflow: auto;
}

.news_pagination .page-link {
  height: 49px;
  width: 49px;
  border-radius: 4px;
  background-color: white;
  color: #000;
}

.news_pagination .page-link {
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #fff !important;
}

.news_pagination .disabled>.page-link {
  background-color: #465272;
  width: 144px;
  height: 49px;
  border-radius: 4px;
}

.news_pagination .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #465272;
  border: 1px solid #dee2e6;
}

.news_pagination li.page-item>a {
  background: #fff;
  color: #000 !important;
  cursor: pointer;
  padding-inline: 30px;
}

.news_pagination li.page-item.active>a {
  background: #007bff;
  color: #fff !important;
}

.line::after {
  content: '';
  position: absolute;
  width: 98%;
  height: 12px;
  z-index: 44;
  top: 100%;
  left: 0;
}

.news_pagination li:first-child>.page-link,
.news_pagination li:last-child>.page-link {
  width: 144px;
  background-color: #1160FF;
  color: #fff !important;
}

.news_pagination .disabled .page-link {
  background-color: #465272 !important;
  cursor: auto;
}

.active-news {
  background-color: #ffffff;
  color: #1160FF;
  font-weight: 600;
}

.docBody {
  overflow-y: hidden;
}

.top-sales-dropdown {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: max-content;
}

.top-sales-dropdown li {
  padding: 17px !important;
}

.top-sales-dropdown.duration-loan li {
  padding: 10px 16px !important;
}

.rc-menu>li.rc-menu-submenu {
  padding: 0 !important;
  font-size: 14px;
  line-height: 20px;
}

.rc-menu-submenu:hover .rc-menu-submenu-popup {
  display: block !important;
}

.rc-menu-submenu.rc-menu-submenu-hidden {
  display: none !important;
}

.homeBtn:hover>svg {
  fill: #fff;
}

.alert {
  opacity: 0;
  visibility: hidden;
}

.tooltip:hover+.alert {
  visibility: visible;
  opacity: 1;
}

.news_article p,
.news_article span,
.news_article ul li,
.news_article h2,
.news_article blockquote {
  font-weight: 500;
  line-height: 24px;
  color: #414141;
  margin-top: 12px;
  font-size: 16px;
}

.news_article iframe {
  @apply max-w-[420px] md:max-w-full;
}

.news_article .wp-block-heading:first-child {
  @apply mt-[19px] text-[32px]/[41px] font-semibold
}

.news_article p {
  @apply font-normal text-base/5
}



.news_article h4,
.news_article h3,
.news_article h5,
.news_article h6,
.news_article p strong {
  font-size: 20px;
  line-height: 29px;
  font-weight: 500;
  color: #414141;
}

.news_article h2 {
  margin-top: 20px;
  font-size: 32px;
  font-weight: 600;
  line-height: 41px;
  color: #414141;
}

.news_article figcaption {
  font-weight: 600;
}

.news_article figure {
  margin: 19px 0;
}

.news_article p a,
.news_article h2 a,
.news_article h4 a,
.news_article h3 a,
.news_article h5 a,
.news_article h6 a {
  color: #1160FF;
}

.custom-line {
  position: absolute;
  width: 1px;
  height: calc(80% - 45px);
  bottom: 45px;
  background: #a9b0c4;
  left: 50%;
  transform: translate(-50%, 0);
}

@media screen and (min-width: 945px) {
  .hr+.hr {
    border-left: 1px solid #ccd6ee;
  }
}

@media screen and (max-width: 1280px) {
  .docBody {
    overflow-y: auto;
  }
}

@media screen and (max-width: 1024px) {
  .GifPickerReact {
    height: 200px !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 639px) {

  .detailSwiper .swiper-button-next,
  .detailSwiper .swiper-button-prev {
    margin-left: 242px;
  }
}

.mobile-search-icon {
  @apply absolute top-0 right-5 bottom-0;
}

@layer components {
  .desktop-search-icon {
    @apply absolute top-0 right-0 bottom-0 px-4;
  }
}

.react-player__preview {
  @apply rounded;
}

.custom-swiper .swiper-pagination {
  @apply text-center md:text-start;
}

.custom-swiper .swiper-pagination-bullet-active {
  @apply bg-primary;
}

.custom-swiper .swiper-pagination-bullet {
  @apply w-[46px] h-[3px] rounded-none;
}


.advertising-swiper {
  position: relative !important;
}

.advertising-swiper .swiper-button-prev,
.advertising-swiper .swiper-button-next {
  min-width: 50px !important;
  height: 50px !important;
  background-color: #1160FF;
  border-radius: 4px;
  position: absolute !important;
  top: 24px;
  transform: translate(90%, 0);
  @apply md:hidden;
}

.advertising-swiper .swiper-button-prev {
  left: -43px;
}

.advertising-swiper .swiper-button-next {
  left: 20px;
}

.advertising-swiper .swiper-button-prev::after,
.swiper-button-next::after {
  color: #fff;
  font-size: 23px !important;
  line-height: 13px !important;
  font-weight: bold !important;
}

.advertising-swiper .swiper-button-disabled {
  background-color: #ccd6ee;
}

.advertising-swiper .swiper-button-disabled::after {
  color: #465272;
}

.advertising-swiper-desktop .swiper-slide {
  height: max-content !important;
}

.advertiseItems+.advertiseItems {
  border-left: 1px solid #dfe3ee;
}

@layer utilities {
  .advertising-swiper {
    @apply absolute top-0;
  }

  .advertising-swiper-desktop .swiper-slide-thumb-active {
    @apply border-l-4 border-primary bg-white;
  }

  .advertising-swiper-desktop .swiper-slide-thumb-active div {
    @apply bg-white;
  }

  .advertising-swiper-desktop .swiper-slide-thumb-active h6 {
    @apply text-primary;
  }
}

@media (min-width: 768px) {
  .advertise-background {
    background-image: url('./assets/homeImages/background.svg');
    background-repeat: no-repeat;
    background-position: -9px 34px;
  }

}

.loader {
  width: 48px;
  height: 100%;
  display: block;
  position: relative;
}

.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #1160FF;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 2s linear infinite;
}

.loader::after {
  animation-delay: 1s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@media screen and (max-width: 1024px) {
  .loader {
  width: 20px;
  height: 20px;
}

.loader::after,
.loader::before {
  width: 16px;
  height: 16px;
}
}

.loginBackground {
  background-image: url('./assets/homeImages/background.svg');
  background-repeat: no-repeat;
  background-position: center;
}

li.page-item:hover>a {
  background-color: #006fff;
  color: white !important;
}

li.page-item:hover>.page-next {
  @apply bg-coal-250;
}

input {
  background-color: #f8f9fb;
  color: #465272;
}

.custom-dropdown:hover,
input:hover {
  background-color: #dfe3ee;
  color: #414141;
}

input:focus {
  background-color: #1160FF;
  color: white;
  outline: none;
  caret-color: white;
}

input:focus+.mobile-search-icon svg,
input:focus+svg,
input:focus+span svg {
  fill: white;
}

input::placeholder {
  color: #465272;
}

input:hover::placeholder {
  color: #414141;
}

input:focus::placeholder {
  color: white;
}

.custom-dropdown:focus {
  background-color: #1160FF;
}

.custom-dropdown:focus span {
  color: white;
  border: none;
}

.token-card:hover .rarity,
.token-card:hover .buy {
  @apply bg-primary text-white;
}

.custom-scrollbar::-webkit-scrollbar {
  @apply w-1;
  height: 1px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-[#9f9f9f] rounded-md;
}

.custom-scrollbar::-webkit-scrollbar-track {
  @apply bg-light-blue-grey dark:bg-jet-black-50 rounded-md;
}

.rc-dropdown {
  z-index: 101 !important;
  font-family: inherit !important;
}

em-emoji-picker {
  --background-rgb: 85, 170, 255;
  --border-radius: 24px;
  --category-icon-size: 24px;
  --color-border-over: 229, 231, 235;
  --color-border: 255, 255, 255;
  --font-family: '__Inter_be6f45', '__Inter_Fallback_be6f45';
  --font-size: 20px;
  --rgb-accent: 0, 111, 255;
  --rgb-background: 229, 231, 235;
  --rgb-color: 32, 43, 70;
  --rgb-input: 255, 255, 255;
  --shadow: 5px 5px 15px -8px grey;

  height: 50vh;
  min-height: 400px;
  max-height: 800px;
}

.editor-answer p,
.editor-answer span {
  @apply font-normal leading-4 text-xs text-white !break-words;
}

.editor-message p,
.editor-message span {
  @apply font-normal leading-4 text-xs !break-words;
}

.editor-message a {
  @apply font-normal leading-4 text-xs !text-primary;
}

.tox-tinymce {
  @apply !border-none !rounded-md;
}

.tox-editor-header {
  @apply !rounded-md !p-0;
}

.tox-edit-area::before {
  @apply !border-none;
}

.tox .tox-edit-area__iframe {
  background-color: transparent !important;
}

#nprogress .bar {
  background: #1160FF !important;
  height: 3px !important;
  z-index: 9999 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 767px) {
  body {
    left: 0;
    right: 0;
  }

}

.active-icon circle {
  @apply fill-primary/15;
}

.active-icon path {
  @apply fill-primary;
}

.home-swiper {
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  min-height: 0;
  min-width: 0;
}