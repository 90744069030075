.dark {
  body {
    color: white;
    @apply border-coal-border;
  }

  input {
    color: #fff;

    &:hover,
    &:focus {
      outline: none !important;
      background-color: none !important;
      color: white;
      caret-color: white;
    }
  }

  hr {
    border: none;
    background-color: #1f1f1f;
  }

  p:not(.text-primary, .dark-values),
  .more-container li,
  .more-container a,
  .more-container h3 {
    color: #fff;
  }

  .dark-values {
    color: #6acc32;
  }

  .rc-menu,
  .rc-menu-item {
    background-color: #141514 !important;
    border-color: #1f1f1f !important;
    color: #fff !important;
  }

  .rc-menu-item {
    cursor: pointer;

    &:hover {
      background-color: #252525 !important;
    }
  }

  input {
    background-color: #212121;
  }

  // .page-link {
  //   background-color: #0d1a3a !important;
  // }
  .news_pagination li:first-child > .page-link,
  .news_pagination li:last-child > .page-link {
    background-color: #131313;
  }

  .rc-menu-item:hover,
  input:hover,
  textarea:hover {
    background: none;
  }

  input::placeholder {
    color: #fff;
  }

  .active-news {
    background-color: #131313;
    border-color: #1f1f1f;
  }

  .activeLink {
    background-color: #131313;
    border-color: #1f1f1f;

    span {
      color: #1160FF;
    }
  }

  .dark-feedback {
    background-color: white;

    * {
      background-color: white;
    }
  }

  button {
    svg path {
      fill: white;
    }
  }

  @media (min-width: 1024px) {
    .homeGeneral {
      background-image: url('/background-dark.svg');
      background-repeat: no-repeat;
      background-position: -2px -57px;
    }
  }

  .advertising-swiper .swiper-button-prev,
  .advertising-swiper .swiper-button-next {
    border: 1px solid #1f1f1f;
  }

  .tox .tox-edit-area__iframe {
    background-color: transparent !important;
  }

  .news_article {
    h3,
    h4,
    h5,
    h6,
    p strong {
      font-size: 20px;
      line-height: 29px;
      font-weight: 500;
      color: #1160FF;
    }
  }
}

.border-r-dashed {
  border-right-style: dashed;
}
